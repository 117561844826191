<template >
  <div class="wrapper">
    <div class="">
      <el-breadcrumb separator="/" class="el-breadcrumb">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
        <el-breadcrumb-item>金融超市</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="finance-info">
        <div class="header">我的贷款产品申请</div>
        <div class="info-content">
          <div class="enterprise-item">
            <div class="name" @click="go">企业名称：<span>温州*******有限公司</span></div>
            <div class="item-info">
              <div style="width: 33.33%">申贷形式：<span class="text">抵(质)押</span></div>
              <div style="width: 33.33%">申贷金额：<span class="text">845.00万元</span></div>
              <div style="width: 33.33%">处理状态：<span class="blue">审核中</span></div>
            </div>
          </div>
          <div class="enterprise-item">
            <div class="name" @click="go">企业名称：<span>温州*******有限公司</span></div>
            <div class="item-info">
              <div style="width: 33.33%">申贷形式：<span class="text">抵(质)押</span></div>
              <div style="width: 33.33%">申贷金额：<span class="text">845.00万元</span></div>
              <div style="width: 33.33%">处理状态：<span class="blue">审核中</span></div>
            </div>
          </div>
          <div class="enterprise-item">
            <div class="name" @click="go">企业名称：<span>温州*******有限公司</span></div>
            <div class="item-info">
              <div style="width: 33.33%">申贷形式：<span class="text">抵(质)押</span></div>
              <div style="width: 33.33%">申贷金额：<span class="text">845.00万元</span></div>
              <div style="width: 33.33%">处理状态：<span class="blue">审核中</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//
export default {
  name: 'demandDetails',
  data() {
    return {};
  },
  methods: {
    go() {
      this.$router.push({ name: 'FinancialDetail' });
    },
  },
  mounted() {},
  created() {},
};
</script>
<style lang="scss" scoped>

.concent {
  padding-left: 83px;
  padding-right: 83px;
  padding-bottom: 15px;
  margin: 0 auto;
  min-width: 1366px;
}
.finance-info {
  margin: 16px 0;
  background-color: #fff;
}

.header {
  font-size: 26px;
  color: #000000;
  line-height: 74px;
  text-indent: 14px;
  border-bottom: 1px solid #dddddd;
  position: relative;
  margin: 0 20px;
}
.header::before {
  content: '';
  background: #4393f8;
  border-radius: 3px;
  width: 4px;
  height: 20px;
  position: absolute;
  top: 27px;
  left: 0;
}
.info-content {
  padding: 16px 20px;
  padding-top: 0;
}

.btn {
  height: 40px;
  line-height: 40px;
}

.btn-sub {
  color: #666;
  margin-left: 12px;
}

.enterprise-item {
  background: #fafafa;
  padding: 0 16px;
  margin-top: 16px;
}

.enterprise-item .name {
  font-size: 14px;
  color: #0e459c;
  line-height: 40px;
  border-bottom: 1px dashed #dddddd;
  cursor: pointer;
}

.enterprise-item .name span {
  font-weight: 600;
}

.enterprise-item .item-info {
  display: flex;
  font-size: 14px;
  color: #999999;
  line-height: 50px;
}

.enterprise-item .item-info .text {
  color: #151515;
}

.blue {
  color: #5babfe;
}
</style>